import AOS from "aos";
import "aos/dist/aos.css";

const acceptTerms = () => {
  const button = $('[rel = "js-terms-button"]');

  button.click(() => {
    localStorage.setItem("termsAccepted", "true");
    $('[rel="js-terms"]').removeClass("visible");
  });
};

const handleBenefitsScroll = () => {
  const titlesList = $('[rel="js-benefits-title"]');
  const imagesList = $('[rel="js-benefits-image"]');
  const stickyContainer = $('[rel="js-benefits-sticky"]');
  const container = $('[rel="js-benefits"]');

  const offsetTop = $(window).width() > 991.9 ? 200 : 400;
  const offsetBottom = $(window).width() > 991.9 ? 500 : 550;

  const scrollOffsetTop = $(window).width() > 767.9 ? 400 : 300;
  const scrollOffsetBottom = $(window).width() > 767.9 ? 200 : 200;

  titlesList.click(function (event) {
    if ($(window).width() > 991.9) {
      $("html, body").animate(
        {
          scrollTop: $(event.currentTarget).offset().top - 300,
        },
        0
      );
    }
  });

  titlesList.each((index, item) => {
    if (
      $(window).scrollTop() >=
        $(titlesList[index]).offset().top - scrollOffsetTop &&
      $(window).scrollTop() <=
        $(titlesList[index]).offset().top - scrollOffsetBottom
    ) {
      $(titlesList).removeClass("active");
      $(imagesList).removeClass("active");
      $(titlesList[index]).addClass("active");
      $(imagesList[index]).addClass("active");
    }
  });

  $(window).scroll(function () {
    if (
      $(window).scrollTop() < container.offset().top - offsetTop ||
      $(window).scrollTop() >
        container.offset().top + container.height() - offsetBottom
    ) {
      $(titlesList).removeClass("active");
      $(imagesList).removeClass("active");
      stickyContainer.removeClass("active");
    } else {
      stickyContainer.addClass("active");
    }

    titlesList.each((index, item) => {
      if (
        $(window).scrollTop() >=
          $(titlesList[index]).offset().top - scrollOffsetTop &&
        $(window).scrollTop() <=
          $(titlesList[index]).offset().top - scrollOffsetBottom
      ) {
        $(titlesList).removeClass("active");
        $(imagesList).removeClass("active");
        $(titlesList[index]).addClass("active");
        $(imagesList[index]).addClass("active");
      }
    });
  });
};

const setFixedHeader = () => {
  const header = $('[rel="js-header"]');
  const offset = $(window).width() > 991.9 ? 30 : 8;

  if ($(window).scrollTop() >= offset) {
    header.addClass("fixed");
  } else {
    header.removeClass("fixed");
  }

  $(window).scroll(function () {
    if ($(window).scrollTop() >= offset) {
      header.addClass("fixed");
    } else {
      header.removeClass("fixed");
    }
  });
};

const scrollToScreen = () => {
  const navLinks = $(".navigation-link");
  const navTargets = $(".navigation-target");

  navLinks.click(function (event) {
    event.preventDefault();

    const id = $(this).attr("href");

    $("html, body").animate(
      {
        scrollTop: $(navTargets.filter(id)).offset().top - 50,
      },
      0
    );
  });
};

$(() => {
  AOS.init();
  acceptTerms();
  handleBenefitsScroll();
  setFixedHeader();
  scrollToScreen();

  if (!localStorage.getItem("termsAccepted")) {
    $('[rel="js-terms"]').addClass("visible");
  }
});
